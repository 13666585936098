import Axios from "../axios";
import { outErr } from "./apiConfig";
const url = "/api/coinAddress"

export default {
    list(out) {
        Axios.get(url + "/list").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    }
}