<template>
    <div class="">
      <div class="user-panel-title-box">
        <h3>{{typeText}}</h3>
      </div><!-- end user-panel-title-box -->
      <div class="profile-setting-panel-wrap" v-loading="loading">
        <div class="table-responsive">
          <table class="table mb-0 table-s2">
            <thead class="fs-14">
            <tr>
              <th scope="col" class="text-center" v-for="(list, i) in tableHead" :key="i">{{ list }}</th>
            </tr>
            </thead>
            <tbody class="table-fs">
            <tr v-for="it in records" :key="it.id">
<!--              <th scope="row"><a href="#">{{ it.id }}</a></th>-->
              <td class="text-center small">{{ getTime(it.updatedAt) }}</td>
              <td class="text-center small">
                <span class="badge fw-medium text-bg-primary" v-if="it.status == 0"> Pending </span>
                <span class="badge fw-medium text-bg-success" v-else-if="it.status == 1"> Paid </span>
                <span class="badge fw-medium text-bg-danger" v-else-if="it.status == 2"> Cancel </span>
                <span class="badge fw-medium text-bg-warning" v-else>
                Invalid
                  <!--                v-b-popover.hover.bottomleft="it.remark"-->
                <i class="el-icon-question"></i>
                </span>
                <p v-if="it.status===-2">  {{ it.remark}}</p>
              </td>
              <td class="text-center small">{{ it.amount }}&nbsp;<span class="forum-title fw-bold font-weight-bold text-uppercase" style="color: rgba(12, 102, 255, 1);font-size: 16px;">{{ it.coinType }} </span></td>
              <td class="text-center small">${{
                  (it.amount *
                      it.usdtExchangeRate).toFixed(2)
                }}
              </td>
              <td class="text-center small">${{ it.balance }}</td>
              <td class="text-center small">
                <span class="badge  text-bg-success" v-if="it.type == 50"> Deposit </span>
                <span class="badge  text-bg-danger" v-else-if="it.type == 60"> Withdraw </span>
              </td>
              <td class="text-center small">{{ maskedAddress(it.address) }}</td>
            </tr>
            </tbody>
          </table>
        </div><!-- end table-responsive -->
        <!-- pagination -->
        <div class="text-center mt-4 mt-md-5">
          <Pagination :records="totalCount" v-model="transForm.pageNum" :per-page="transForm.pageSize"></Pagination>
        </div>
      </div><!-- end profile-setting-panel-wrap-->
    </div><!-- end col-lg-8 -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import Pagination from 'v-pagination-3';
import transApi from "@/apis/transApi";
import date from "@/date";

export default {
  name: 'MyDepositHistorySection',
   components: {
    Pagination
  },
  props:{
    typeText: {
      type: String,
      default: ''
    },
    type: {
      type: [String,Number],
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      tableHead: [
        // '#','Date','Currency','Status','Amount','USDT','Balance','Type', 'Wallet Address'
        'Date','Status','Amount','USD','Balance','Type', 'Wallet Address'
      ],
      records: [],
      transForm: {
        status: undefined,
        type: this.type,
        pageNum: 1,
        pageSize: 10
      },
      totalCount: 0,
      totalPages: 1
    }
  },
  watch: {
    'transForm.pageNum': function () {
      this.getTable()
    }
  },
  computed: {

  },
  mounted() {
    this.getTable()
  },
  methods:{
    getTable(){
      this.loading = true;
      transApi.list(this.transForm, res => {
        this.loading = false;
        // console.log(res)
        this.records = res.data?.result || [];
        this.totalCount = res.data?.totalCount || 0;
        this.totalPages = res.data?.totalPages || 1;
        // if (this.trans.result.length <= this.transForm.pageSize) this.transLoad = -1
      })
    },
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    },
    search(){
      this.transForm.pageNum = 1;
      this.totalCount = 0;
      this.totalPages = 0;
      this.records = [];
      this.getTable()
    },
    maskedAddress(addr) {
      if(addr){
        const length = addr.length
        const keepLength = Math.min(Math.floor(length / 4), 5)
        const prefix = addr.slice(0, keepLength)
        const suffix = addr.slice(-keepLength)
        return `${prefix}****${suffix}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

.table-fs {
  font-size: 0.975rem;
}


</style>
