<template>
  <div class="Deposit">
    <div class="">
      <div class="user-panel-title-box">
        <h2>Withdraw</h2>
        <!--        <h6 style="margin-top: 4px">We offer the following payment methods</h6>-->
      </div><!-- end user-panel-title-box -->
      <div v-if="showBound">
        <h4 style="color: Red;">{{ withdrawBound.title }}</h4>
        <div class="form" v-loading="load">
          <div class="item">
            <!--            <div class="item-label">Upgrade to VIP</div>-->
            <div class="item">Required for VIP upgrade</div>
            <div class="item-control">
              <!--              <input type="number" placeholder="Please enter amount " v-model="amount">-->
              <span style="color:red;">$ {{ withdrawBound.toVipAmount }}</span>
            </div>
          </div>
          <div class="item" style="margin-top: 1.25rem">
            <div class="item">Total deposit</div>
            <div class="item-control">
              <span style="color:green;">$ {{ withdrawBound.depositAmount }}</span>
            </div>
          </div>
          <div class="item" style="margin-top: 1.25rem">
            <div class="item">Required for deposit</div>
            <div class="item-control">
              <span style="color:red;">$ {{ withdrawBound.remainingAmount }}</span>
            </div>
          </div>
          <div class="item" style="margin-top: 1.25rem" v-if="withdrawBound.remark!=='' && withdrawBound.remark!==null">
            <div class="item">Notes</div>
            <div class="item-control">
              <span style="color:black;">{{ withdrawBound.remark }}</span>
            </div>
          </div>
        </div>
      </div>


      <div class="profile-setting-panel-wrap" v-if="!showBound">
        <div class="form" v-loading="load">
          <div class="item">
            <div class="item-label">Address</div>
            <div class="item-control">
              <input type="text" placeholder="Enter your address" v-model="submitForm.transferCoinAddress">
            </div>
          </div>
          <div class="item">
            <div class="item-label" style="margin-top: 1.25rem">Amount(USD)</div>
            <div class="item-control">
              <input type="number" placeholder="Please enter amount " v-model="amount">
            </div>
          </div>


          <div class="item" style="margin-top: 1.25rem; position: relative;">
            <div class="item-label">Gateway</div>


<!--            <div class="dropdown">-->
<!--              <div class="dropdown-menu card-generic p-2 keep-open w-60 mt-1 show"-->
<!--                   data-popper-placement="bottom-start"-->
<!--                   style=" inset: 0px auto auto 0px; margin: 0px;">-->
<!--                <div @click="selectCoinAddress(item)" class="dropdown-item card-generic-item"-->
<!--                     v-for="item in coinAddress" :key="item.id">-->
<!--                  <div :class="{'card-generic-item-active': currentCoin?.id === item.id}">-->
<!--                    <img style="width:28px;" :src="require('@/images/icon/'+ item.alias.toLowerCase() + '.svg')"  alt="svg"/>-->
<!--                    <span style="margin-left: 5px;">{{ item.name }} </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <el-select v-model="currentCoin" :placeholder="currentCoin ? null : 'Select gateway'"  class="large-select" @change="handleChange" value-key="id">
              <template v-if="currentCoin" #prefix>
                <img :src="getIconUrl(currentCoin.alias)" alt="icon" class="icon" />
                <span :style="{ color: '#000' }">{{ currentCoin.name }}</span>
              </template>
              <!--                    :label="item.name"-->
              <el-option
                  v-for="item in coinAddress"
                  :key="item.id"
                  :value="item"
              >
                <div class="option-content">
                  <img :src="getIconUrl(item.alias)" alt="icon" class="icon" />
                  <span>{{ item.name }}</span>
                </div>
              </el-option>
            </el-select>

          </div>

          <div style="margin-top: 1.25rem" v-if="alert.show">
            <el-alert show-icon :title="alert.text" type="error" :closable="false"></el-alert>
          </div>

          <div class="row gy-3 but-wrap mt-3">
            <div class="col-12 col-sm-6 convert"
                 v-if="currentCoin && (amount !== null && amount !== undefined && amount !== '')">
              <div class="me-3">
                <!--                <p class="p1"> Convert </p>-->
                <p class="p2"> {{ amount }} USD = {{ convert }}
                  <img :src="require('@/images/icon/'+ currentCoin.alias.toLowerCase() + '.svg')"  alt="svg"/>
                  {{ currentCoin.alias }}
                </p>
              </div>
            </div>
            <div class="col-12 col-sm-6 justify-content-lg">
              <button class="btn btn-grad w-100" style="max-width: 300px"
                      :disabled="!(currentCoin && amount && submitForm.transferCoinAddress)" @click="withdraw"> Submit
              </button>
            </div>
          </div>
        </div>
      </div><!-- end profile-setting-panel-wrap-->
      <!--      <MyDepositHistorySection></MyDepositHistorySection>-->
      <my-deposit-history-section class="mt-4" type-text="History" type="60"></my-deposit-history-section>
    </div><!-- end col-lg-8 -->

    <!-- Modal -->
    <!--    <b-modal></b-modal>-->


  </div>
</template>

<script>
import coinAddressApi from "@/apis/coinAddressApi"
import indexApi from "@/apis/indexApi";
import btnClose from "@/images/icon/btn-close.svg"
import walletApi from "@/apis/walletApi";
import MyDepositHistorySection from "@/components/n1/MyDepositHistorySection";

export default {
  name: "Withdraw",
  components: {MyDepositHistorySection},

  data() {
    return {
      showBound: false,
      btnClose: btnClose,
      coinAddress: [],
      currentCoin: '',
      // convert: '1000usdt=1000000.EHT',
      amount: '',
      roe: {
        price: 1
      },
      load: false,
      type: "usdt",
      min: 0,
      rate: '',
      exampleModal: false,
      QrCode: '',
      walletAddress: '',
      submitForm: {
        addressConfId: "",
        amount: "",
        rate: '',
        transferCoinAddress: '',
      },
      btnData: [
        {
          btnClass: 'btn-lg btn-dark',
          title: 'Explore',
          path: '/explore'
        },
        {
          btnClass: 'btn-lg btn-outline-dark',
          title: 'Create',
          path: '/create'
        }
      ],
      alert: {
        text: "",
        show: false
      },
      fileList: [],
      withdrawBound: {
        isShow: 0,
        title: '',
        toVipAmount: 0,
        depositAmount: 0,
        remainingAmount: 0,
        remark: ''
      },
    }
  },
  computed: {
    convert: function () {
      if (!this.amount || !this.currentCoin) return '0.00';
      return ((this.amount / this.roe.price)).toFixed(8);
    }
  },
  created() {
    walletApi.preWithdrawCheck(res => {
      if (res.data !== 'pass') {
        let extraJson = res.data.extraJson
        if (extraJson !== null && extraJson !== '') {
          let innerBound = {
            isShow: extraJson.isShow || 0,
            title: extraJson.title || '',
            toVipAmount: extraJson.toVipAmount || 0,
            depositAmount: extraJson.depositAmount || 0,
            remainingAmount: extraJson.remainingAmount || 0,
            remark: extraJson.remark || ''
          }
          try {
            const parsedJson = JSON.parse(extraJson)
            innerBound = {...innerBound, ...parsedJson}
            if (innerBound.isShow === 1) {
              this.showBound = true
            } else {
              this.showBound = false
            }
          } catch (error) {
            console.error('Invalid JSON string:', error)
          }
          this.withdrawBound = innerBound
        }
      }
    })
    coinAddressApi.list(res => {
      this.coinAddress = res.data
    })
  },
  methods: {
    getIconUrl(alias) {
      return require(`@/images/icon/${alias.toLowerCase()}.svg`)
    },
    handleChange(item) {
      this.selectCoinAddress(item)
    },
    withdraw() {
      if (this.submitForm.addressConfId === "") {
        this.alert.show = true
        this.alert.text = "Coin address cannot be empty"
        return
      }
      if (this.amount === "") {
        this.alert.show = true
        this.alert.text = "Amount cannot be empty"
        return
      }
      if (this.submitForm.transferCoinAddress === "" || this.submitForm.transferCoinAddress === undefined) {
        this.alert.show = true
        this.alert.text = "Coin address cannot be empty"
        return
      }
      this.load = true
      this.submitForm.amount = this.amount === undefined ? '0.00' : ((this.amount / this.roe.price)).toFixed(8)
      this.submitForm.rate = this.rate
      walletApi.withdraw(this.submitForm, res => {
        this.load = false
        if (res.code !== 200) {
          this.alert.show = true
          this.alert.text = res.msg
        } else {
          this.$message.success("Pending..")
          this.alert.show = false
          this.submitForm = {
            addressConfId: "",
            amount: "",
            rate: '',
            transferCoinAddress: ""
          }
          this.min = 0
          this.amount = 0
          this.rate = ''
        }
      })
    },

    copysuccess() {
      this.$message.success("Copied")
    },
    copyerror() {
      this.$message.success("Fail")
    },
    selectCoinAddress(item) {
      if (!this.amount) {
        this.$messageBox.alert(
            'Please enter the amount first.',
            'Error',
            {
              type: 'error',
              center: false,
            })
        this.currentCoin = 'n'
        return
      }
      this.currentCoin = item;
      console.log(item.id)
      this.submitForm.addressConfId = item?.id || '';
      this.load = true
      let data = item;
      this.walletAddress = data.address
      this.QrCode = data.addressQrCode
      this.type = data.alias
      this.min = data.minimumWithdrawAmount
      indexApi.roe(data.alias, res => {
        this.load = false
        this.roe = res.data == null ? 1 : res.data
        this.rate = res.data.price
      })

    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-menu {
  z-index: 2;
  position: relative;
}

.card-generic-item:hover {
  background-color: #f8f9fa;
  color: #7952b3;
}
.card-generic-item-active {
  color: red;
}


.form {
  //width: 687px;
  //height: 370px;
  background: #F8F9FC;
  border-radius: 0.5rem;
  padding: 1.3125rem;

  .item {
    &-label {
      height: 1.3125rem;
      font-size: .9375rem;
      //font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333947;
      line-height: 1.3125rem;
    }

    &-control {
      margin-top: 0.625rem;

      input {
        width: 100%;
        height: 2.6875rem;
        border: 1px solid #FFFFFF;
        padding: 0 1rem;
        background: #FFFFFF;
        border-radius: 4px;
        //height: 1.3125rem;
        font-size: .9375rem;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1C2A46;
        line-height: 2.6875rem;
        //caret-color:  rgba(var(--bs-link-color-rgb));
        transition: all .3s ease;

        &:focus {
          outline: none;
          border: 1px solid rgba(var(--bs-link-color-rgb));
        }

        &::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #8091A7;
        }

        &:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #8091A7;

        }

        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #8091A7;

        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #8091A7;

        }


      }


      .select {
        //margin: 0;
        &-i {
          background: #FFFFFF;
          border-radius: 4px;
          padding: 1.25rem 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid transparent;
          transition: all 0.3s ease;

          &.active {
            border-color: rgba(var(--bs-link-color-rgb));
          }

          span {
            margin-top: 0.625rem;
            font-size: 0.9375rem;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1C2A46;
            line-height: 1.3125rem;
          }
        }
      }
    }
  }

}

.info-wrap {
  background: #F4F4F4;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: red;
  line-height: 1.375rem;
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.convert {
  display: flex;
  align-items: flex-end;
  font-size: 1rem;
  line-height: 1.375rem;

  .p1 {
    color: #1C2A46;
  }

  .p2 {
    color: red;
    //color: #198754;
    margin-top: 2px;
  }
}

.pay-info {
  &_codeQr {

  }

  &_right {
    .item {
      .label {
        font-size: 0.9375rem;
        //font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1C2A46;
        line-height: 1.3125rem;
      }

      .value {
        font-size: 1.125rem;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1C2A46;
        line-height: 1.5625rem;
        margin-top: 0.1875rem;
      }
    }

    .convert {
      //margin-top: 1.5625rem;
    }
  }

}

.el-icon {
  cursor: pointer;

  &:hover {
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  }
}

.el-upload-list-wrap {
  .el-upload-list__item {
    .el-upload-list__item-status-label {
      display: block;
    }

    .el-icon--close {
      display: none;
    }

    &:hover {
      .el-upload-list__item-status-label {
        display: none;
      }

      .el-icon--close {
        display: block;
      }
    }
  }

}

.upload-demo {
  ::v-deep .el-upload {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 70vw;
  }
}



.large-select .el-input__inner {
  display: flex;
  align-items: center;
}

.large-select .icon {
  width: 24px;
  margin-right: 5px;
}

.option-content {
  display: flex;
  align-items: center;
}
.icon {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}


.el-select {
  margin-top: 5px;
  width: 100%;
  border-radius: 4px;
  font-size: 0.9375rem;
  font-weight: 400;
  color: #1C2A46;
  line-height: 2.6875rem;
  transition: all 0.3s ease;
}


</style>
